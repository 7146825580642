<template>
    <div class="row">
        <div class="col-md-12">
            <span>Categorías de Publicación</span>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'

export default {
    setup(props) {

        return {
        }
    }
}
</script>

<style>

</style>
